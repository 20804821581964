import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { PageContext } from "../PageContext";
import { RCL as useTranslation } from "../RCL";
import { Icon } from "../Icons";
import { Heading, HeadingAsElement } from "../Heading";
import { SearchBox } from "./SearchBox";
import * as styles from "./SearchBox.module.scss";

const SearchBoxConnector = ({ data, language, type: providedType }) => {
	const dict = {
		genericLabel: useTranslation({ searchKey: "search-for-your-next-vacation" }),
		packagesLabel: useTranslation({ searchKey: "search-for-your-next-vacation-all-inclusive" }),
		flightsLabel: useTranslation({ searchKey: "search-for-your-next-vacation-flights" }),
		cruisesLabel: useTranslation({ searchKey: "search-for-your-cruise-adventure" }),
		hotelsLabel: useTranslation({ searchKey: "search-for-your-perfect-hotel" }),
	};
	const ctx = useContext(PageContext);
	const [isLoading, setIsLoading] = useState(true);
	const alias = ctx?.alias?.alias || data?.alias || "drv";
	let type = data?.type || providedType;
	switch (type) {
		case "other":
		case "other with widget":
		case "lastminute":
		case "small groups":
			type = "packages";
			break;
		default:
			break;
	}
	let label;
	switch (type) {
		case "packages":
			label = dict.packagesLabel;
			break;
		case "flights":
			label = dict.flightsLabel;
			break;
		case "cruises":
			label = dict.cruisesLabel;
			break;
		case "hotels":
			label = dict.hotelsLabel;
			break;
		default:
			label = dict.genericLabel;
			break;
	}
	return (
		<div className={styles.searchBoxRoot} data-type={type} data-mode={data?.mode || "search"}>
			<Heading as={HeadingAsElement.div} size="h3" className={styles.searchBoxTitle}>
				<Icon name="search" className={styles.searchBoxTitleIcon} /> {label}
			</Heading>
			<div
				className={styles.searchBoxContent}
				data-type={type}
				data-mode={data?.mode || "search"}
				data-loading={isLoading}
			>
				{!ctx?.aliasLoading && (
					<SearchBox
						language={language}
						alias={alias}
						type={type}
						mode={data?.mode || "search"}
						setIsLoading={setIsLoading}
					/>
				)}
			</div>
		</div>
	);
};

SearchBoxConnector.propTypes = {
	data: PropTypes.object.isRequired,
	language: PropTypes.string,
	type: PropTypes.oneOf([
		"packages",
		"flights",
		"hotels",
		"cruises",
		"other",
		"lastminute",
		"small groups",
		"other with widget",
	]),
};

SearchBoxConnector.defaultProps = {
	language: "en",
	type: "packages",
};

export default SearchBoxConnector;
export { SearchBoxConnector };
