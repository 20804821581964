/* eslint-disable react/no-danger */
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Img } from "../../../sharedComponents/Images";
import { Card } from "../Card";
import { HeadingAsElement } from "../../Heading";
import * as styles from "./PrimaryCard.module.scss";

const CardVariants = ["normal", "condensed", "compact", "horizontal"];

const getAspectRatioClass = (width, height) => {
	const aspectRatio = width / height;

	if (aspectRatio === 1) {
		return "square";
	}
	if (aspectRatio <= 2 && aspectRatio > 1) {
		return "landscape";
	}
	if (aspectRatio > 2) {
		return "wide";
	}
	return "wide";
};

const PrimaryCard = ({
	className,
	image,
	logo,
	header,
	variant,
	content,
	button,
	link,
	headerAs,
	// eslint-disable-next-line no-unused-vars
	LinkComponent,
}) => {
	const aspectRatioClass = getAspectRatioClass(logo?.data?.width, logo?.data?.height);
	return (
		<div
			className={cx(styles.primary, {
				[styles.compact]: variant === "compact",
				[className]: className,
			})}
		>
			<div className={styles.primaryImageWrapper}>
				<Img className={styles.primaryImage} image={image} alt={image?.alt || "image"} />
			</div>
			<Card.Body className={styles.primaryBody}>
				<div className={styles.primaryHeader}>
					{header && variant !== "compact" && (
						<Card.Header
							size={variant === "normal" || variant === "horizontal" ? "h3" : "h3"}
							as={headerAs}
							className={styles.primaryHeading}
						>
							{header}
						</Card.Header>
					)}
					{content && <Card.Content className={styles.primarySubheading}>{content}</Card.Content>}
				</div>
				<div className={styles.primaryFooter}>
					{button?.to && (
						<div>
							<Card.Button
								to={button?.to}
								target={button?.target}
								theme="quaternary"
								icon="arrow-thick-right"
								iconPlacement="right"
								className={styles.primaryButton}
								onClick={link?.onClick}
								label={button?.text}
							/>
						</div>
					)}
					{link?.to && (
						<div>
							<Card.Button
								size="md"
								theme="quaternary"
								to={link.to}
								icon="arrow-thick-right"
								iconPlacement="right"
								target={link?.target}
								className={styles.primaryButton}
								onClick={link?.onClick}
								label={link?.text}
							/>
						</div>
					)}
					{logo && variant === "normal" && (
						<div
							className={cx(styles.primaryLogo, {
								[styles.square]: aspectRatioClass === "square",
								[styles.landscape]: aspectRatioClass === "landscape",
								[styles.wide]: aspectRatioClass === "wide",
							})}
						>
							<Img
								width={logo?.data?.width}
								height={logo?.data?.height}
								src={logo?.data?.src}
								alt={logo?.data?.alt || "image"}
							/>
						</div>
					)}
				</div>
			</Card.Body>
			{link?.to && (
				<Card.AsLink
					link={link?.to}
					target={link?.target}
					text={header || link?.text}
					className={styles.primaryCardLink}
					LinkComponent={LinkComponent}
					onClick={link?.onClick}
				/>
			)}
		</div>
	);
};

PrimaryCard.propTypes = {
	variant: PropTypes.oneOf([...CardVariants]),
	className: PropTypes.string,
	/**
	 * Required image
	 */
	image: PropTypes.shape({
		data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
		source: PropTypes.shape({
			src: PropTypes.string,
			srcSet: PropTypes.string,
			sizes: PropTypes.string,
		}),
		alt: PropTypes.string.isRequired,
		src: PropTypes.string,
		width: PropTypes.number,
		height: PropTypes.number,
	}).isRequired,
	/**
	 * Renders optional logo on card image
	 */
	logo: PropTypes.string,
	/**
	 * Required header
	 */
	header: PropTypes.string.isRequired,
	content: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
	headerAs: PropTypes.oneOf(Object.keys(HeadingAsElement)),
	/**
	 * Renders if onClick is available
	 */
	button: PropTypes.shape({
		icon: PropTypes.string,
		iconPlacement: PropTypes.oneOf(["right", "left"]),
		onClick: PropTypes.func,
		text: PropTypes.string,
		to: PropTypes.string,
		target: PropTypes.string,
	}),
	/**
	 * Renders links to appear as button and will be applied to card
	 */
	link: PropTypes.shape({
		to: PropTypes.string.isRequired,
		target: PropTypes.string,
		icon: PropTypes.shape({
			svg: PropTypes.shape({
				content: PropTypes.string,
			}),
		}),
		text: PropTypes.string,
		onClick: PropTypes.func,
	}),
	LinkComponent: PropTypes.elementType,
};

PrimaryCard.defaultProps = {
	variant: "normal",
	className: "",
	content: undefined,
	button: null,
	link: null,
	logo: null,
	headerAs: HeadingAsElement.h2,
	LinkComponent: undefined,
};

export default PrimaryCard;
export { PrimaryCard, CardVariants };
