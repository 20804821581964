/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useSpring, animated } from "@react-spring/web";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { RCL as useTranslation } from "../../../RCL";
import { getCookie } from "../../../../utils/helper";
// eslint-disable-next-line import/no-cycle
import { Button } from "../../../Button";
// eslint-disable-next-line import/no-cycle
import { EmailAcquisitionForm } from "../../EmailAcquisitionForm";

import * as styles from "./Mobile.module.scss";

const fallbackLabels = {
	title: "Subscribe now!",
	confirmTitle: "Thank you",
};

const hideWidget = { marginBottom: -100 };
let prevScrollY = 0;

const Mobile = ({
	config,
	mode,
	isWidgetOpen,
	setWidgetOpen,
	lang,
	userEmail,
	setUserEmail,
	userGateway,
	setUserGateway,
	setRegistered,
	isRegistered,
	viewportBreakpoint,
	offsetTop,
	exitIntentActive,
	setExitIntentActive,
	exitIntentCookieName,
	exitIntentEnabled,
	handleExitDismiss,
	embed,
}) => {
	// Modal controls
	const isOpen = useRef(isWidgetOpen);
	const [viewportWidth, setViewportWidth] = useState(null);
	const [viewportHeight, setViewportHeight] = useState(null);
	const [viewportHeightPrev, setViewportHeightPrev] = useState(null);
	const [widgetCSS, setWidgetCSS] = useState(isWidgetOpen ? null : hideWidget);
	const hasResized = useRef(false);
	const updateHeight = useRef(false);
	const formContainerRef = useRef(null);
	const formComponentRef = useRef(null);
	const formTriggerRef = useRef(null);
	const [hasSubmitted, setHasSubmitted] = useState(false);
	const [displayWidget, setDisplayWidget] = useState(false);
	const widgetWrapperRef = useRef(null);
	const initDelay = useRef(true);
	const isChatListener = useRef(false);
	const dataAttr = "scrollLocked";

	// Setup form resize animations
	const [style, animate] = useSpring(() => ({
		height: "0px",
		transition: "height 0.25s ease-out",
	}));

	const defaultLabels = {
		title: useTranslation({ searchKey: "subscribe-to-our-sov-newsletter" }),
		confirmTitle: useTranslation({
			searchKey: "sign-up-confirmation-title",
		}),
	};

	let labels = {
		...fallbackLabels,
		...defaultLabels,
	};

	const marketingContent = {
		title: config?.marketing?.[config?.type]?.mobileWidget?.title || labels.title,
	};

	labels = {
		...labels,
		...marketingContent,
	};

	if (exitIntentActive) {
		labels = {
			...labels,
			title: config?.marketing?.exit?.mobileWidget?.title || labels.title,
		};
	}

	const handleDisplayForm = useCallback(
		(openOverride = null) => {
			isOpen.current = openOverride || !isWidgetOpen;
			setWidgetOpen(openOverride || !isWidgetOpen);

			// Hide widgets upon successful registration
			if (sessionStorage.getItem(`${config.id}-registration`) === "1") {
				setRegistered("1");
			}

			if (isOpen.current === true && formContainerRef?.current) {
				disableBodyScroll(formContainerRef.current);
				document.body.dataset[dataAttr] = "true";
			} else {
				delete document.body.dataset[dataAttr];
				clearAllBodyScrollLocks();
			}
		},
		[config.id, isWidgetOpen, setRegistered, setWidgetOpen]
	);

	const attachChatEventListener = useCallback(() => {
		if (!isChatListener.current && config?.type === "default") {
			// Setup Messenger subscribe events
			if (window?.Genesys) {
				window.Genesys("subscribe", "Messenger.ready", () => {
					// Add Messenger chat class
					const launcher = document.getElementsByClassName("genesys-app");
					launcher[0].classList.add("ea-active");

					window.Genesys("subscribe", "Messenger.opened", () => {
						setDisplayWidget(false);
						document.body.dataset[dataAttr] = "true";
					});

					window.Genesys("subscribe", "Messenger.closed", () => {
						setDisplayWidget(true);
						delete document.body.dataset[dataAttr];
					});
				});

				isChatListener.current = true;
			}
		}
	}, [config?.type]);

	const handleResize = useCallback(() => {
		// Setup FB subscribe events
		attachChatEventListener();

		// Verify that the open form doesn't need resizing
		if (formComponentRef?.current && formContainerRef?.current && isOpen.current) {
			updateHeight.current =
				formContainerRef.current.getBoundingClientRect().height !==
				formComponentRef.current.getBoundingClientRect().height;

			if (updateHeight.current && formTriggerRef?.current) {
				const animateHeight = exitIntentActive
					? window.innerHeight - formTriggerRef.current.getBoundingClientRect().height + 2
					: formComponentRef.current.getBoundingClientRect().height;

				// Handle the widget height adjustment when page resizes using the component height
				animate({
					height: `${animateHeight}px`,
				});
				setWidgetCSS(null);
			}
		}

		hasResized.current = true;

		// Continue tracking viewport width with fallback
		setViewportWidth(window.innerWidth || document.documentElement.clientWidth);

		// Track viewport height for exit intent
		setViewportHeight(window.innerHeight || document.documentElement.clientHeight);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [animate, attachChatEventListener, exitIntentActive]);

	const handleScroll = () => {
		// Setup FB subscribe events
		attachChatEventListener();

		hasResized.current = false;
		let direction = 0;

		if (prevScrollY > window.scrollY) {
			direction = -1;
		} else if (prevScrollY < window.scrollY) {
			direction = 1;
		}

		if (formContainerRef?.current && !isOpen.current) {
			// Define the header and footer DOM elements to constrain movement
			const pageScrollTopLimit = document
				.getElementsByClassName("page__header")[0]
				.getBoundingClientRect().height;

			const pageScrollBottomLimit =
				document.scrollingElement.scrollHeight -
				formContainerRef.current.getBoundingClientRect().top -
				document.getElementsByTagName("footer")[0].getBoundingClientRect().height;

			const inDisabledRegion =
				!isWidgetOpen &&
				(window.scrollY - offsetTop < pageScrollTopLimit ||
					window.scrollY + offsetTop > pageScrollBottomLimit);

			if ((!isWidgetOpen && direction === -1) || inDisabledRegion) {
				setWidgetCSS(hideWidget);
			} else {
				setWidgetCSS(null);
			}
		}

		prevScrollY = window.scrollY;
	};

	useEffect(() => {
		// Scroll event listener
		window.addEventListener("scroll", handleScroll, false);

		// Resize event listener
		window.addEventListener("resize", handleResize, false);

		// Setup FB subscribe events
		attachChatEventListener();

		const getIFrame = document.querySelectorAll("[data-testid='dialog_iframe']");

		if (getIFrame.length > 0) {
			const dialogHeight = parseInt(getIFrame[0].style.minHeight, 10);
			const isChatOpen = Number.isNaN(dialogHeight) && dialogHeight > 0;

			if (!isChatOpen) {
				setDisplayWidget(true);
			}
		} else {
			setDisplayWidget(true);
		}

		initDelay.current = false;
		handleResize();

		return () => {
			window.removeEventListener("resize", handleResize);
			window.removeEventListener("scroll", handleScroll);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		/* Handle the display of the widget when resizing the viewport
			 isWidgetOpen: tracks if the UI was open or closed by user, and will be used to restore UI state
			 across mobile and desktop components
		*/

		if (!isWidgetOpen) {
			// Force close mobile widget
			isOpen.current = false;
			clearAllBodyScrollLocks();
			delete document.body.dataset[dataAttr];

			// Safari fix
			document.getElementsByTagName("body")[0].style.overflow = null;

			animate({
				height: "0px",
			});
		} else if (isWidgetOpen && formContainerRef?.current) {
			// Force open mobile widget
			isOpen.current = true;
			disableBodyScroll(formContainerRef.current);
			document.body.dataset[dataAttr] = "true";

			handleResize();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		animate,
		handleResize,
		isRegistered,
		isWidgetOpen,
		viewportBreakpoint,
		viewportWidth,
		viewportHeight,
		viewportHeightPrev,
	]);

	useEffect(() => {
		/* Handle the display of the exit intent widget when resizing the viewport */

		if (exitIntentEnabled && document?.getElementsByTagName("body")) {
			if (
				viewportHeight !== null &&
				viewportHeightPrev - viewportHeight > 40 &&
				viewportWidth < viewportBreakpoint &&
				!getCookie(exitIntentCookieName) &&
				!isWidgetOpen &&
				!isRegistered
			) {
				setWidgetOpen(true);
				setExitIntentActive(true);
				// Safari fix
				document.getElementsByTagName("body")[0].style.overflow = "hidden";
			}

			setViewportHeightPrev(window.innerHeight);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		exitIntentCookieName,
		exitIntentEnabled,
		isRegistered,
		isWidgetOpen,
		viewportBreakpoint,
		viewportHeight,
		viewportHeightPrev,
		viewportWidth,
	]);

	useEffect(() => {
		// Triggered when viewport resizing causes the resize method to force an update in form container height
		if (formContainerRef?.current || (formContainerRef?.current && updateHeight.current)) {
			updateHeight.current = false;

			const exitIntentHeight = formTriggerRef?.current
				? formTriggerRef.current.getBoundingClientRect().height
				: 0;

			const animateHeight = exitIntentActive
				? window.innerHeight - exitIntentHeight + 2
				: formComponentRef.current.scrollHeight;

			animate({
				height: `${isWidgetOpen ? animateHeight : 0}px`,
			});
		}
	}, [animate, exitIntentActive, isWidgetOpen]);

	useEffect(() => {
		// Triggered when form inputs / height changes during validation / error messages / successful submission
		// Handle registration states 1 and 2
		if (
			(config?.type !== "inline" &&
				formComponentRef?.current &&
				(hasSubmitted || isRegistered !== null)) ||
			(config?.type === "inline" &&
				formComponentRef?.current &&
				(hasSubmitted || isRegistered === "2"))
		) {
			updateHeight.current = true;
			setHasSubmitted(false);

			const exitIntentHeight = formTriggerRef?.current
				? formTriggerRef.current.getBoundingClientRect().height
				: 0;

			const animateHeight = exitIntentActive
				? window.innerHeight - exitIntentHeight + 2
				: formComponentRef.current.getBoundingClientRect().height;

			animate({
				height: `${animateHeight}px`,
			});

			if (config?.type !== "inline" && isRegistered === "2") {
				clearAllBodyScrollLocks();
				document.body.dataset[dataAttr] = "true";
			} else if (!isWidgetOpen && config?.type === "inline" && isRegistered === "2") {
				setWidgetOpen(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		animate,
		config?.type,
		exitIntentActive,
		hasSubmitted,
		isRegistered,
		isWidgetOpen,
		viewportWidth,
	]);

	return (
		<>
			{viewportWidth && viewportWidth < viewportBreakpoint && displayWidget && !initDelay.current && (
				<div
					style={{
						touchAction: "none",
						position: "fixed",
						width: "100%",
						zIndex: 99,
						bottom: "0px",
					}}
				>
					<div
						ref={widgetWrapperRef}
						className={cx(
							styles.mobileWidgetWrapper,
							isWidgetOpen ? styles.open : null,
							exitIntentActive ? styles.exitIntent : null
						)}
						style={
							(config?.type === "inline" || config?.type === "exit") && !isWidgetOpen
								? hideWidget
								: widgetCSS
						}
					>
						<div className={cx(styles.container, styles.handle)}>
							<div ref={formTriggerRef}>
								<Button
									noStyle
									className={styles.formToggle}
									onClick={() => {
										handleDisplayForm();
										handleExitDismiss();
									}}
									iconPlacement="left"
									icon={isRegistered !== "2" ? "email--o" : null}
								>
									<span className={styles.heading}>
										{isRegistered === "2" ? labels.confirmTitle : labels.title}
									</span>
								</Button>
							</div>

							<div className={cx(styles.formContents)}>
								<animated.div
									className={styles.formContainer}
									ref={formContainerRef}
									style={{
										...style,
									}}
								>
									<EmailAcquisitionForm
										embed={embed}
										ref={formComponentRef}
										config={config}
										mode={mode}
										setWidgetOpen={setWidgetOpen}
										lang={lang}
										userEmail={userEmail}
										setUserEmail={setUserEmail}
										userGateway={userGateway}
										setUserGateway={setUserGateway}
										setRegistered={setRegistered}
										isRegistered={isRegistered}
										setHasSubmitted={setHasSubmitted}
										setExitIntentActive={setExitIntentActive}
										exitIntentActive={exitIntentActive}
										exitIntentCookieName={exitIntentCookieName}
										exitIntentEnabled={exitIntentEnabled}
										handleExitDismiss={handleExitDismiss}
									/>
								</animated.div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

Mobile.propTypes = {
	config: PropTypes.object.isRequired,
	mode: PropTypes.string.isRequired,
	isWidgetOpen: PropTypes.bool.isRequired,
	setWidgetOpen: PropTypes.func.isRequired,
	lang: PropTypes.oneOf(["en", "fr"]).isRequired,
	userEmail: PropTypes.string,
	setUserEmail: PropTypes.func.isRequired,
	userGateway: PropTypes.object,
	setUserGateway: PropTypes.func.isRequired,
	setRegistered: PropTypes.func.isRequired,
	isRegistered: PropTypes.string,
	viewportBreakpoint: PropTypes.number.isRequired,
	offsetTop: PropTypes.number.isRequired,
	setExitIntentActive: PropTypes.func.isRequired,
	exitIntentActive: PropTypes.bool.isRequired,
	exitIntentCookieName: PropTypes.string.isRequired,
	exitIntentEnabled: PropTypes.bool.isRequired,
	handleExitDismiss: PropTypes.func.isRequired,
	embed: PropTypes.bool,
};

Mobile.defaultProps = {
	userEmail: "",
	userGateway: undefined,
	isRegistered: null,
	embed: true,
};

export default Mobile;
export { Mobile };
