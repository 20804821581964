import React from "react";

/**
 *  The Noibu SDK may not load initially. This checks if it loads after the page renders
 */
export const useNoibu = (maxAttempts = 10, delay = 500) => {
	const [attempts, setAttempts] = React.useState(0);
	const [exists, setExists] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(true);
	const intervalRef = React.useRef(null);

	const getHelpCode = React.useCallback(async () => {
		const code = await window.NOIBUJS?.requestHelpCode(false);
		return code;
	}, []);

	React.useEffect(() => {
		intervalRef.current = setInterval(() => {
			setAttempts(val => val + 1);
			if (window.NOIBUJS) {
				setExists(true);
			}
		}, delay);

		return () => {
			clearInterval(intervalRef.current);
		};
	}, [delay]);

	React.useEffect(() => {
		if (exists || attempts > maxAttempts) {
			setIsLoading(false);
			clearInterval(intervalRef.current);
		}
	}, [exists, attempts, maxAttempts]);

	return { exists, isLoading, attempts, getHelpCode };
};

export default useNoibu;
