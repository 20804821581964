import React, { useEffect } from "react";
import PropTypes from "prop-types";
import deepmerge from "deepmerge";

let _isLoaded = false;
const _eventQueue = [];

const setBookingSearchBox = (
	{ from, to, isHotel = false, force = false } = {},
	priority = 100,
	trigger = false
) => {
	if (!trigger) {
		const settings = {
			packages: {},
			flights: {},
			cruises: {},
		};

		if (from) {
			settings.packages.selectedFrom = {
				value: from,
				force,
			};
			settings.flights.selectedFrom = {
				value: from,
				force,
			};
			settings.cruises.selectedFrom = {
				value: from,
				force,
			};
		}

		if (to) {
			settings.packages.selectedTo = {
				value: to,
				isHotel,
				force,
			};
		}

		_eventQueue.push({ settings, priority });
	}

	if (!_isLoaded && !trigger) {
		return false;
	}

	const _prioritizedQueue = _eventQueue
		.sort((a, b) => a.priority - b.priority)
		.map(event => event.settings);

	if (_prioritizedQueue.length) {
		const settings = deepmerge.all(_prioritizedQueue);
		window.WEB_CDN_BSB.overrideDefaults(settings);
		_eventQueue.length = 0;
	}

	return true;
};

const SearchBox = ({ type, language, mode, alias, setIsLoading }) => {
	useEffect(() => {
		const bsbScript = document.createElement("script");
		bsbScript.src = process.env.GATSBY_WEB_CDN_BSB;
		bsbScript.async = true;
		bsbScript.defer = true;
		bsbScript.onload = () => {
			const preferredGatewayValue = JSON.parse(localStorage.getItem("preferredGateway"))?.value;
			window.WEB_CDN_BSB.onLoad(() => {
				setBookingSearchBox(
					{
						from: preferredGatewayValue,
					},
					10
				);
				_isLoaded = true;
				setIsLoading(false);
				setBookingSearchBox({}, null, true);
			});
		};

		document.body.appendChild(bsbScript);
		return () => {
			if (bsbScript) {
				_isLoaded = false; // Reset the flag
				setIsLoading(true);
				if (window.WEB_CDN_BSB && window.WEB_CDN_BSB.destroy) {
					window.WEB_CDN_BSB.destroy();
				}
				bsbScript?.remove();
			}
		};
	}, []);

	// Determine the search type to display

	return (
		<div
			id="booking-search-box-wrapper"
			data-lang={language}
			data-alias={alias}
			data-type={type}
			data-mode={mode}
			data-brand="sov"
		/>
	);
};

SearchBox.propTypes = {
	type: PropTypes.oneOf(["packages", "flights", "hotels", "cruises"]),
	language: PropTypes.oneOf(["en", "fr"]),
	mode: PropTypes.oneOf(["search", "tabs"]),
	alias: PropTypes.string,
	setIsLoading: PropTypes.func,
};

SearchBox.defaultProps = {
	type: "packages",
	language: "en",
	mode: "search",
	alias: "drv",
	setIsLoading: () => {},
};

export default SearchBox;
export { SearchBox };
