// extracted by mini-css-extract-plugin
export var sovIcon = "NoibuLink-module--sov-icon--3EOw_";
export var sovIconAdd = "NoibuLink-module--sov-icon-add--1KUoG";
export var sovIconAlertO = "NoibuLink-module--sov-icon-alert--o--39Gz5";
export var sovIconAlert = "NoibuLink-module--sov-icon-alert--3aO40";
export var sovIconArrowDouble = "NoibuLink-module--sov-icon-arrow-double--3sqvE";
export var sovIconArrowLeft = "NoibuLink-module--sov-icon-arrow-left--36vMj";
export var sovIconArrowRight = "NoibuLink-module--sov-icon-arrow-right--19sNw";
export var sovIconArrowThickDown = "NoibuLink-module--sov-icon-arrow-thick-down--2L6HJ";
export var sovIconArrowThickUp = "NoibuLink-module--sov-icon-arrow-thick-up--2FLuH";
export var sovIconCalendarAlt = "NoibuLink-module--sov-icon-calendar-alt--16Pul";
export var sovIconCalendar = "NoibuLink-module--sov-icon-calendar--XLnC8";
export var sovIconCheck = "NoibuLink-module--sov-icon-check--2D3HP";
export var sovIconChecked = "NoibuLink-module--sov-icon-checked--36nry";
export var sovIconCheckmark = "NoibuLink-module--sov-icon-checkmark--l-Yxs";
export var sovIconCloseN = "NoibuLink-module--sov-icon-close--n--2usKe";
export var sovIconClose = "NoibuLink-module--sov-icon-close--3ToxJ";
export var sovIconCruiseAlt = "NoibuLink-module--sov-icon-cruise-alt--3D9L6";
export var sovIconCruise = "NoibuLink-module--sov-icon-cruise--_NsnO";
export var sovIconEurope = "NoibuLink-module--sov-icon-europe--1fYGs";
export var sovIconExternal = "NoibuLink-module--sov-icon-external--2OwIy";
export var sovIconFacebook = "NoibuLink-module--sov-icon-facebook--3Q8Ym";
export var sovIconFaq = "NoibuLink-module--sov-icon-faq--2trz8";
export var sovIconFavorite = "NoibuLink-module--sov-icon-favorite--1QL51";
export var sovIconFilters = "NoibuLink-module--sov-icon-filters--2XSrx";
export var sovIconFlight = "NoibuLink-module--sov-icon-flight--18jEP";
export var sovIconFlights = "NoibuLink-module--sov-icon-flights--3GjMx";
export var sovIconGlobe = "NoibuLink-module--sov-icon-globe--2Z57u";
export var sovIconHotel = "NoibuLink-module--sov-icon-hotel--1bt7L";
export var sovIconInclusive = "NoibuLink-module--sov-icon-inclusive--2UIPJ";
export var sovIconInformation = "NoibuLink-module--sov-icon-information--sk_KC";
export var sovIconInstagram = "NoibuLink-module--sov-icon-instagram--1oNhM";
export var sovIconLastmin = "NoibuLink-module--sov-icon-lastmin--2-iF-";
export var sovIconMap = "NoibuLink-module--sov-icon-map--3wlw-";
export var sovIconMenu = "NoibuLink-module--sov-icon-menu--2RLI5";
export var sovIconMinusN = "NoibuLink-module--sov-icon-minus--n--2Az3v";
export var sovIconParking = "NoibuLink-module--sov-icon-parking--3oRBP";
export var sovIconPhone = "NoibuLink-module--sov-icon-phone--2bGR6";
export var sovIconPinterest = "NoibuLink-module--sov-icon-pinterest--3twu_";
export var sovIconPlusN = "NoibuLink-module--sov-icon-plus--n--3KvLp";
export var sovIconStarHalf = "NoibuLink-module--sov-icon-star-half--3LO5N";
export var sovIconStar = "NoibuLink-module--sov-icon-star--2ln9l";
export var sovIconTimer = "NoibuLink-module--sov-icon-timer--NLuct";
export var sovIconTravel = "NoibuLink-module--sov-icon-travel--1lo0c";
export var sovIconTwitter = "NoibuLink-module--sov-icon-twitter--i1zNw";
export var sovIconUplift = "NoibuLink-module--sov-icon-uplift--alFKb";
export var sovIconVideo = "NoibuLink-module--sov-icon-video--KUl62";
export var sovIconYoutube = "NoibuLink-module--sov-icon-youtube--1JiT4";
export var sovIconSearch = "NoibuLink-module--sov-icon-search--AxTgw";
export var sovIconComplaints = "NoibuLink-module--sov-icon-complaints--2DFPd";
export var sovIconCustomerAssistance = "NoibuLink-module--sov-icon-customer-assistance--2r_sd";
export var sovIconReview = "NoibuLink-module--sov-icon-review--LpyUd";
export var sovIconEmailO = "NoibuLink-module--sov-icon-email--o--2-7VU";
export var sovIconRefreshO = "NoibuLink-module--sov-icon-refresh--o--1YNfy";
export var sovIconArrowThickRight = "NoibuLink-module--sov-icon-arrow-thick-right--TKmNP";
export var sovIconAtm = "NoibuLink-module--sov-icon-atm--3Kqz8";
export var sovIconBeach = "NoibuLink-module--sov-icon-beach--3FkLN";
export var sovIconBusiness = "NoibuLink-module--sov-icon-business--2K0Qw";
export var sovIconDoctor = "NoibuLink-module--sov-icon-doctor--2g5e0";
export var sovIconGolf = "NoibuLink-module--sov-icon-golf--2vYZG";
export var sovIconGroupsconference = "NoibuLink-module--sov-icon-groupsconference--ZFsse";
export var sovIconGym = "NoibuLink-module--sov-icon-gym--2LaiP";
export var sovIconHealthsaftey = "NoibuLink-module--sov-icon-healthsaftey--3Kduk";
export var sovIconIron = "NoibuLink-module--sov-icon-iron--2pxSn";
export var sovIconKids = "NoibuLink-module--sov-icon-kids--Bd8Ms";
export var sovIconMinibar = "NoibuLink-module--sov-icon-minibar--3-MhX";
export var sovIconNightclub = "NoibuLink-module--sov-icon-nightclub--2vXCp";
export var sovIconSafe = "NoibuLink-module--sov-icon-safe--aMzA0";
export var sovIconShowers = "NoibuLink-module--sov-icon-showers--jvfLu";
export var sovIconSnorkelling = "NoibuLink-module--sov-icon-snorkelling--29hZo";
export var sovIconSpa = "NoibuLink-module--sov-icon-spa--3lkKH";
export var sovIconSportsrecreation = "NoibuLink-module--sov-icon-sportsrecreation--2-pOT";
export var sovIconSteambath = "NoibuLink-module--sov-icon-steambath--2etgR";
export var sovIconSunloungers = "NoibuLink-module--sov-icon-sunloungers--hKKfj";
export var sovIconSwimmingpool = "NoibuLink-module--sov-icon-swimmingpool--3qs3f";
export var sovIconWaterpark = "NoibuLink-module--sov-icon-waterpark--1TwDN";
export var sovIconWifi = "NoibuLink-module--sov-icon-wifi--1pvQo";
export var sovIconAccommodation = "NoibuLink-module--sov-icon-accommodation--23rwb";
export var sovIconAmenities = "NoibuLink-module--sov-icon-amenities--3ZUsL";
export var sovIconAmericanexpress = "NoibuLink-module--sov-icon-americanexpress--1_pYu";
export var sovIconDestination = "NoibuLink-module--sov-icon-destination--1GLTh";
export var sovIconEdit = "NoibuLink-module--sov-icon-edit--G-eAz";
export var sovIconFaq1 = "NoibuLink-module--sov-icon-faq1--1AoB0";
export var sovIconHotelpolicies = "NoibuLink-module--sov-icon-hotelpolicies--177Sn";
export var sovIconHotels = "NoibuLink-module--sov-icon-hotels--3xGwM";
export var sovIconLowestprice = "NoibuLink-module--sov-icon-lowestprice--wU1qc";
export var sovIconMastercard = "NoibuLink-module--sov-icon-mastercard--3eAj4";
export var sovIconMeals = "NoibuLink-module--sov-icon-meals--3AxOz";
export var sovIconOverview = "NoibuLink-module--sov-icon-overview--3hnBM";
export var sovIconPorts = "NoibuLink-module--sov-icon-ports--XuAbv";
export var sovIconPreorder = "NoibuLink-module--sov-icon-preorder--1zCNw";
export var sovIconSort = "NoibuLink-module--sov-icon-sort--2roJ_";
export var sovIconSunny = "NoibuLink-module--sov-icon-sunny--1_qCv";
export var sovIconTrophy = "NoibuLink-module--sov-icon-trophy--qKd7d";
export var sovIconVisa = "NoibuLink-module--sov-icon-visa--3-wFe";
export var sovIconPricematchpolicy = "NoibuLink-module--sov-icon-pricematchpolicy--2qlHx";
export var sovIconDeals = "NoibuLink-module--sov-icon-deals--nSafE";
export var sovIconArcade = "NoibuLink-module--sov-icon-arcade--3zFgc";
export var sovIconBowling = "NoibuLink-module--sov-icon-bowling--1Wi_c";
export var sovIconCasino = "NoibuLink-module--sov-icon-casino--2GvqZ";
export var sovIconEntertain = "NoibuLink-module--sov-icon-entertain--1yGig";
export var sovIconFacilites = "NoibuLink-module--sov-icon-facilites--2yeI2";
export var sovIconLaundry = "NoibuLink-module--sov-icon-laundry--1-T9H";
export var sovIconLobby = "NoibuLink-module--sov-icon-lobby--1ZxFL";
export var sovIconOtherentertainment = "NoibuLink-module--sov-icon-otherentertainment--JI8g9";
export var sovIconShop = "NoibuLink-module--sov-icon-shop--U9VcL";
export var sovIconTheatre = "NoibuLink-module--sov-icon-theatre--343qB";
export var sovIconTransfers = "NoibuLink-module--sov-icon-transfers--1dA3H";
export var noibuLink = "NoibuLink-module--noibuLink--10g9r";