import React, { useState, useEffect, useContext, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import dayjs from "dayjs";
// eslint-disable-next-line import/no-cycle
import { CardsContainerGrid } from "../CardsContainer";
import { AdModuleCard } from "../Card";
import { Tabs } from "../Tabs";
import {
	LOCAL_EN,
	LOCAL_FR,
	AD_MODULE_OFFER_TYPE_PACKAGE,
	AD_MODULE_OFFER_TYPE_ONE_WAY_FLIGHT,
	LOCAL_STORAGE_KEY_PREFERRED_GATEWAY,
	DEFAULT_GATEWAY_EN,
	DEFAULT_GATEWAY_FR,
} from "../../const/const";
import { RCL, RCL as useTranslation } from "../RCL";
import "dayjs/locale/fr";
import { PageContext } from "../PageContext";
import { addSEOSupportToURL } from "../../utils/gatsby-helpers";
import { useIsMobile } from "../../utils/useIsMobile";
// eslint-disable-next-line import/no-cycle
import { CollapsibleLinksList } from "../../sharedComponents/CollapsibleLinksList";
import gatewaysGroup from "../Header/Gateway/Menu/HeaderGateways.mock.json";
import * as styles from "./HotelCardTabs.module.scss";

/* TODO:: Dropdown will be replace by new Sunwing input component */
const RenderCards = ({
	data,
	locale,
	labels,
	isMobile,
	numberToShow,
	moreToShow,
	cardsPerRow,
	loadMoreVariant,
}) => {
	const validOffers = data.Offers?.filter(offer =>
		dayjs(offer.DepartureDate).isAfter(dayjs().startOf("day"))
	);

	return (
		<div id="travel-deals">
			<CardsContainerGrid
				numberToShow={numberToShow}
				moreToShow={moreToShow}
				cardsPerRow={cardsPerRow}
				loadMoreVariant={loadMoreVariant}
				labels={{
					loadAll: labels.loadAll,
					loadMore: labels.loadMore,
				}}
			>
				{validOffers?.map((i, index) => {
					if (!i) {
						return <></>;
					}

					const dayOfWeek = dayjs(i.DepartureDate, "YYYY-MM-D").format("ddd");
					const date = dayjs(i.DepartureDate, "YYYY-MM-D").format("MMM D, YYYY");
					const Duration = i.Duration ? `${i.Duration} ${labels.days}` : "";
					const returnDate = dayjs(i.DepartureDate).add(i.Duration, "day").format("MMM D, YYYY");
					let modifiedLink = i.DeepLink;

					if (isMobile) {
						const splitLink = i.DeepLink.split("?");
						const modifiedParams = [...splitLink[1].split("&"), "isMobile=true"];

						const aliasIndex = modifiedParams.indexOf("alias=drv");
						if (aliasIndex > 0) {
							modifiedParams[aliasIndex] = "alias=mrv";
						}

						modifiedLink = `${splitLink[0]}?${modifiedParams.join("&")}`;
					}
					if (i.OfferType === AD_MODULE_OFFER_TYPE_PACKAGE) {
						return (
							<AdModuleCard
								key={i.AccommodationInfo?.AccommodationCode ?? index}
								variant="hotel"
								header={`${i.Destination.Name}, ${i.Destination.CountryName}`}
								link={{
									to: modifiedLink,
									target: "_blank",
									text: i.AccommodationInfo.AccommodationName,
								}}
								subheader={i.AccommodationInfo.AccommodationName}
								stars={parseFloat(i.AccommodationInfo.StarRating)}
								days={i.Duration ? `${i.Duration} ${labels.days}` : ""}
								price={i.Price}
								save={i.SaveUpto && `${labels.saveUpTo} ${i.SaveUpto}%`}
								local={locale}
								labels={{
									allInc: i.MealPlan,
									taxAndFee: i.tax,
									departureDate: date,
								}}
							/>
						);
					}
					return (
						<AdModuleCard
							variant="flight"
							key={i.AccommodationInfo?.AccommodationCode ?? index}
							link={{
								to: i.DeepLink,
								target: "_blank",
								text: i.Destination?.Name,
							}}
							header={i.Destination?.Name}
							days={
								i.OfferType === AD_MODULE_OFFER_TYPE_ONE_WAY_FLIGHT
									? labels.oneWay
									: `${Duration} | ${labels.roundTrip}`
							}
							price={i.Price}
							local={locale}
							labels={{
								from: i.Gateway.Name,
								dayOfWeek,
								departureDate: date,
								taxAndFee: i.tax,
								returnDate,
							}}
						/>
					);
				})}
			</CardsContainerGrid>
		</div>
	);
};

const HotelCardTabs = ({ data, locale, numberToShow, moreToShow, cardsPerRow }) => {
	const pageContext = useContext(PageContext);
	const labels = {
		loadAll: useTranslation({ searchKey: "load-all" }),
		loadMore: useTranslation({ searchKey: "load-more" }),
		adModuleDisclaimer: useTranslation({ searchKey: "ad-module-disclaimer" }),
		days: useTranslation({ searchKey: "days" }),
		saveUpTo: useTranslation({ searchKey: "save-up-to" }),
		oneWay: useTranslation({ searchKey: "one-way" }),
		roundTrip: useTranslation({ searchKey: "sov-round-trip" }),
	};

	const getDefaultGateway = useCallback(() => {
		let code = pageContext.locale === LOCAL_EN ? DEFAULT_GATEWAY_EN : DEFAULT_GATEWAY_FR;
		if (pageContext?.gateway?.Code) {
			code = pageContext?.gateway?.Code;

			// Set default gateway (temporary) in the booking search box based on the specific ad module page
			/*
			setBookingSearchBox(
				{
					from: pageContext.gateway.Code,
					force: true,
				},
				1000
			);
			*/
		}
		const _gateway = data.find(i => i?.Gateway?.Code === code);
		if (_gateway) {
			return _gateway.Gateway;
		}
		return data?.[0].Gateway;
	}, [pageContext, data]);
	const isMobile = useIsMobile();
	const [defaultGateway, setDefaultGateway] = useState(getDefaultGateway());
	const gateways = useMemo(
		() => {
			const mockGateways = gatewaysGroup.reduce((acc, item) => [...acc, ...item.gateways], []);
			return data
				?.filter(m => m)
				.map(m => {
					const mockGateway = mockGateways.find(gateway => gateway.value === m.Gateway.Code);
					const gateway = { ...m.Gateway };
					if (mockGateway) gateway.Name = mockGateway.label;
					return gateway;
				})
				.filter(m => m)
				.filter(m => m.Code !== defaultGateway.Code);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[data, defaultGateway.Code]
	); // defaultGatewayCode change after loading from local storage doesn't fire this hook if we just use defaultGatewayCode

	const [tabsData, setTabsData] = useState(
		data.find(i => i?.Gateway?.Code === defaultGateway.Code)
	);

	useEffect(() => {
		try {
			if (pageContext?.gateway?.Code !== defaultGateway.Code) {
				const localStorageGatewayCode = JSON.parse(
					localStorage.getItem(LOCAL_STORAGE_KEY_PREFERRED_GATEWAY)
				);
				if (localStorageGatewayCode?.value) {
					const _gateway = data.find(i => i?.Gateway?.Code === localStorageGatewayCode?.value);
					if (_gateway) {
						setDefaultGateway(_gateway.Gateway);
					} else {
						setDefaultGateway(getDefaultGateway());
					}
				}
			}

			setTabsData(
				data.find(i => i?.Gateway?.Code === defaultGateway.Code) || data?.[0].Gateway?.Code
			);
		} catch (err) {
			console.error(err);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, pageContext]);
	useEffect(() => {
		setTabsData(
			data.find(i => i?.Gateway?.Code === defaultGateway.Code) || data?.[0].Gateway?.Code
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultGateway]);
	const links = pageContext.basePath
		? gateways.map((gateway, index) => ({
				url: addSEOSupportToURL(`${pageContext.basePath}/from-${gateway?.Name}`),
				linkText: gateway?.Name,
				id: index,
		  }))
		: gateways.map((gateway, index) => ({
				linkText: gateway?.Name,
				id: index,
				onClick: () => {
					setDefaultGateway(gateway);
				},
		  }));
	return tabsData?.PromotionGroups ? (
		<>
			{links && (
				<div className={cx(styles.dropdownPanWrap)}>
					<div className={cx(styles.dropdownPan)}>
						<CollapsibleLinksList
							listHeading={RCL({ searchKey: "from" })}
							variant="classic"
							listHeadingLevel="span"
							expanded={false}
							disabled={data.length === 1}
							linksGroup={[
								{
									entries: links,
								},
							]}
							customStyles={styles}
							labels={{
								allCategories: (
									<span className={styles.button}>
										{defaultGateway.Name} {data.length !== 1 && <span className={styles.chevron} />}
									</span>
								),
							}}
						/>
					</div>
				</div>
			)}
			{tabsData?.PromotionGroups.length === 1 ? (
				<>
					<div className={cx({ singleTabHeader: links })} />
					<RenderCards
						data={tabsData?.PromotionGroups[0]}
						locale={locale}
						labels={labels}
						isMobile={isMobile}
						numberToShow={numberToShow}
						moreToShow={moreToShow}
						cardsPerRow={cardsPerRow}
					/>
				</>
			) : (
				<Tabs
					className={cx(styles.tabs)}
					data={tabsData?.PromotionGroups.map(tab => ({
						key: tab.title,
						toggle: {
							label: {
								heading: tab.title,
							},
						},
						panel: (
							<RenderCards
								data={tab}
								locale={locale}
								labels={labels}
								isMobile={isMobile}
								numberToShow={numberToShow}
								moreToShow={moreToShow}
								cardsPerRow={cardsPerRow}
							/>
						),
					}))}
				/>
			)}
			<p>{labels.adModuleDisclaimer}</p>
		</>
	) : (
		""
	);
};

RenderCards.propTypes = {
	data: PropTypes.shape({
		Description: PropTypes.string,
		Offers: PropTypes.arrayOf(
			PropTypes.shape({
				AccommodationInfo: PropTypes.shape({
					AccommodationCode: PropTypes.string,
					AccommodationName: PropTypes.string,
					StarRating: PropTypes.number,
				}),
				CalendarLink: PropTypes.string,
				DeepLink: PropTypes.string,
				DepartureDate: PropTypes.string,
				Destination: PropTypes.shape({
					CountryName: PropTypes.string,
					Name: PropTypes.string,
					ProviderCode: PropTypes.string,
				}),
				Duration: PropTypes.number,
				Gateway: PropTypes.shape({
					Code: PropTypes.string,
					Name: PropTypes.string,
				}),
				MealPlan: PropTypes.string,
				OfferType: PropTypes.string,
				Price: PropTypes.number,
				RegPrice: PropTypes.number,
				SaveUpto: PropTypes.number,
				tax: PropTypes.string,
			})
		),
		title: PropTypes.string,
	}).isRequired,
	locale: PropTypes.oneOf([LOCAL_FR, LOCAL_EN]),
	labels: PropTypes.shape({
		loadAll: PropTypes.string,
		loadMore: PropTypes.string,
		adModuleDisclaimer: PropTypes.string,
		days: PropTypes.string,
		saveUpTo: PropTypes.string,
		oneWay: PropTypes.string,
		roundTrip: PropTypes.string,
	}),
	isMobile: PropTypes.bool,
	numberToShow: PropTypes.number,
	moreToShow: PropTypes.number,
	cardsPerRow: PropTypes.number,
	loadMoreVariant: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
};
RenderCards.defaultProps = {
	locale: LOCAL_EN,
	labels: {
		loadAll: "Load all",
		loadMore: "Load more",
		adModuleDisclaimer:
			"The prices shown reflect rates of the day and are subject to change at any time without prior notice. To confirm availability and final pricing you MUST proceed to Step 3. The price shown at Step 3 constitutes the final guaranteed price and prevails over any other price.",
		days: "days",
		saveUpTo: "Save up to",
		oneWay: "One way",
		roundTrip: "Round trip",
	},
	isMobile: false,
	numberToShow: 12,
	moreToShow: 12,
	cardsPerRow: 4,
	loadMoreVariant: "tertiary",
};

HotelCardTabs.propTypes = {
	numberToShow: PropTypes.number,
	moreToShow: PropTypes.number,
	cardsPerRow: PropTypes.number,
	data: PropTypes.arrayOf(
		PropTypes.shape({
			Gateway: PropTypes.shape({
				Code: PropTypes.string,
				Name: PropTypes.string,
			}),
			PromotionGroups: PropTypes.arrayOf(
				PropTypes.shape({
					Description: PropTypes.string,
					Offers: PropTypes.arrayOf(
						PropTypes.shape({
							AccommodationInfo: PropTypes.shape({
								AccommodationCode: PropTypes.string,
								AccommodationName: PropTypes.string,
								StarRating: PropTypes.number,
							}),
							CalendarLink: PropTypes.string,
							DeepLink: PropTypes.string,
							DepartureDate: PropTypes.string,
							Destination: PropTypes.shape({
								CountryName: PropTypes.string,
								Name: PropTypes.string,
								ProviderCode: PropTypes.string,
							}),
							Duration: PropTypes.number,
							Gateway: PropTypes.shape({
								Code: PropTypes.string,
								Name: PropTypes.string,
							}),
							MealPlan: PropTypes.string,
							OfferType: PropTypes.string,
							Price: PropTypes.number,
							RegPrice: PropTypes.number,
							SaveUpto: PropTypes.number,
							tax: PropTypes.string,
						})
					),
					title: PropTypes.string,
				})
			),
		})
	).isRequired,
	locale: PropTypes.oneOf([LOCAL_FR, LOCAL_EN]),
};
HotelCardTabs.defaultProps = {
	locale: LOCAL_EN,
	numberToShow: 12,
	moreToShow: 12,
	cardsPerRow: 4,
};

export default HotelCardTabs;
export { HotelCardTabs };
