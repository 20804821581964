import dayjs from "dayjs";
import { graphql, navigate, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FooterConnector } from "../../connectors/FooterConnector";
import { HeaderConnector } from "../../connectors/HeaderConnector";
import { SEOConnector } from "../../connectors/SEOConnector";
import { getCookie, setCookie, setLanguageCookie, removeCookie } from "../../utils/helper";
import useInterval from "../../utils/useInterval";
import { ExitModal, TimedOutModal, WarningModal } from "../Affiliates";
import { PageContext } from "../PageContext";
import { SocialMetaConnector } from "../SocialMeta";
import { DotsLoader } from "../Loader";
import { CookieAcceptanceBanner } from "../CookieAcceptanceBanner";
import * as styles from "./BaseLayout.module.scss";

const BaseLayout = ({
	children,
	location,
	pageContext,
	seo,
	altLangPages,
	footerData,
	headerNavigation,
	homepagePaths,
	alerts,
	travelAlertsUrl,
	pageType,
	isAffiliatePage,
}) => {
	const { site } = useStaticQuery(graphql`
		{
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);
	const { siteUrl } = site.siteMetadata;
	const lang = pageContext?.locale.substring(0, 2).toLowerCase();
	// eslint-disable-next-line no-unused-vars
	const [linkURL, setLinkURL] = useState(null);

	const currentPage = altLangPages?.edges.find(
		page =>
			page.node?.context?.locale?.substring(0, 2).toLowerCase() ===
			pageContext.locale?.substring(0, 2)?.toLowerCase()
	);
	const pageUrl = `${siteUrl}${currentPage?.node?.path}`;

	const englishPage = altLangPages?.edges.find(
		page => page.node?.context?.locale?.substring(0, 2).toLowerCase() === "en"
	);
	const englishPageUrl = `${siteUrl}${englishPage?.node?.path}`;

	useEffect(() => {
		// Set language cookie if it doesn't exist
		if (getCookie("nf_lang") !== lang) {
			setLanguageCookie(lang);
		}
	}, [lang]);

	// This can be null || 'EXPIRED' || 'ACTIVE' || 'WARNING'
	const [affiliateState, setAffiliateState] = useState(null);
	const [exitModal, setExitModal] = useState(false);

	const ctx = useContext(PageContext);

	useEffect(() => {
		// If the page isn't an affiliate page, clear the affiliate state, ctx alias, and cookie
		if (!isAffiliatePage) {
			setAffiliateState(null);
			ctx.setAlias(null);
			removeCookie("affiliate-session", "/", ".selloffvacations.com");
		}
	}, [ctx, isAffiliatePage]);

	useEffect(() => {
		// If on "travel-perks-program", and theres no cookie, redirect to homepage
		if (isAffiliatePage && !getCookie("affiliate-session")) {
			navigate(`/${lang}/`);
		}
	}, [lang, isAffiliatePage]);

	const handleRefreshSession = () => {
		if (typeof window !== "undefined") {
			try {
				const newSession = window.STGI.affiliates.extend(30);
				window.STGI.affiliates.decode(newSession).then(res => ctx.setAlias(res));
				setAffiliateState("ACTIVE");
				if (newSession) {
					setCookie(
						"affiliate-session",
						JSON.stringify({ code: newSession }),
						"/",
						".selloffvacations.com"
					);
				}
			} catch {
				window.location.replace(`${window.location.origin}`);
			}
		}
	};

	// Set up timer
	const [warningModalTrigger, setWarningModalTrigger] = useState(false);

	// redirect from affiliates if no token
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(async () => {
		const URLToken = getCookie("affiliate-session");

		if (isAffiliatePage) {
			if (!URLToken) {
				navigate(`/${lang}/`);
			}
		}

		if (URLToken?.code) {
			const alias = await window.STGI?.affiliates?.decode(URLToken.code);

			if (!alias) {
				ctx?.setAliasLoading(false);
				navigate(`/${lang}/`);
				return;
			}
			ctx?.setAlias(alias);

			const sessionMinutes = Math.abs(dayjs(new Date()).diff(alias?.expiryTime, "minutes"));

			if ((alias?.title && alias?.isExpired) || dayjs().isAfter(alias?.expiryTime)) {
				setAffiliateState("EXPIRED");
			} else if (sessionMinutes <= 15 && !alias?.isExpired) {
				setAffiliateState("WARNING");
				setWarningModalTrigger(true);
			} else {
				setAffiliateState("ACTIVE");
			}
		}
		ctx?.setAliasLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useInterval(
		() => {
			if (ctx?.aliasLoading === false) {
				const sessionMinutes = Math.abs(dayjs(new Date()).diff(ctx?.alias?.expiryTime, "minutes"));

				if (ctx?.alias?.isExpired || dayjs().isAfter(ctx?.alias?.expiryTime)) {
					setAffiliateState("EXPIRED");
				} else if (sessionMinutes <= 15 && !ctx?.alias?.isExpired) {
					setAffiliateState("WARNING");
				} else {
					setAffiliateState("ACTIVE");
				}
			}
		},
		ctx.alias === undefined ? null : 1000
	);

	useEffect(() => {
		const handleBeforeUnload = event => {
			event.preventDefault();
			// eslint-disable-next-line no-param-reassign
			event.returnValue = ""; // This is necessary for Chrome

			return false;
		};

		const handleLinkClick = event => {
			event.preventDefault();
			const href = event.target.getAttribute("href");

			setExitModal(true);
			setLinkURL(href);
		};

		if (isAffiliatePage) {
			window.addEventListener("beforeunload", handleBeforeUnload);
			const links = document.querySelectorAll("a");

			links.forEach(link => {
				const target = link.getAttribute("target");
				if (!target) {
					link.addEventListener("click", handleLinkClick);
				}
			});
		}

		return () => {
			if (isAffiliatePage) {
				window.removeEventListener("beforeunload", handleBeforeUnload);
				const links = document.querySelectorAll("a");

				links.forEach(link => {
					const target = link.getAttribute("target");
					if (!target) {
						link.removeEventListener("click", handleLinkClick);
					}
				});
			}
		};
	}, [isAffiliatePage]);

	const handleCookieBannerOnload = () => {
		document.body.classList.add(styles?.cookieBanner);
	};

	const handleCookieBannerOnUnload = () => {
		document.body.classList.remove(styles?.cookieBanner);
	};

	return (
		<div className="page">
			<Helmet
				htmlAttributes={{
					lang: pageContext?.locale ? pageContext.locale.split("-")[0] : "en",
					pagetype: pageType || "undefined",
				}}
			>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<meta httpEquiv="X-UA-Compatible" content="IE=edge" />
				<meta name="referrer" content="no-referrer" />
				<meta name="rating" content="general" />
				<meta name="distribution" content="global" />
				<meta name="classification" content="travel" />
				{/* Default Title if page does not have SEO setup */}
				<title>selloffvacations.com/</title>
				<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
				<link rel="manifest" href="/site.webmanifest" />
				<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#008fbe" />
				<meta name="msapplication-TileColor" content="#ffffff" />
				<meta name="theme-color" content="#ffffff" />

				{!seo && <link rel="canonical" href={pageUrl} />}
				<link rel="alternate" href={englishPageUrl} hrefLang="x-default" />
				{altLangPages &&
					altLangPages.edges.map(page => (
						<link
							key={page.node.context.id}
							rel="alternate"
							href={`${siteUrl}${page.node.path}`}
							hrefLang={page.node.context.locale}
						/>
					))}
			</Helmet>
			{seo && <SEOConnector metaData={seo} />}
			{seo && (
				<SocialMetaConnector
					location={location}
					pageUrl={pageUrl}
					metaData={seo}
					locale={pageContext.locale}
				/>
			)}
			<HeaderConnector
				affiliateName={ctx?.alias?.title}
				affiliateCode={ctx?.alias?.alias}
				isAffiliate={!!ctx?.alias?.alias}
				headerNavigation={headerNavigation}
				altLangPages={altLangPages}
				homepagePaths={homepagePaths}
				alerts={alerts}
				travelAlertsUrl={travelAlertsUrl}
				pageType={pageType}
				setExitModal={setExitModal}
			/>
			{isAffiliatePage && ctx?.aliasLoading ? (
				<div className={styles?.loader}>
					<DotsLoader />
				</div>
			) : (
				<main className="page__container">{children}</main>
			)}
			{!ctx.aliasLoading && ctx.alias && (
				<>
					{affiliateState === "EXPIRED" && <TimedOutModal isOpen={true} />}
					{affiliateState === "WARNING" && warningModalTrigger && (
						<WarningModal
							setAffiliateState={setAffiliateState}
							isOpen={warningModalTrigger}
							setIsOpen={setWarningModalTrigger}
							refresh={handleRefreshSession}
						/>
					)}
					<ExitModal isOpen={exitModal} setExitModal={setExitModal} linkURL={linkURL} />
				</>
			)}
			{footerData && <FooterConnector data={footerData} altLangPages={altLangPages} />}

			<CookieAcceptanceBanner
				onLoad={handleCookieBannerOnload}
				onUnload={handleCookieBannerOnUnload}
			/>
		</div>
	);
};

BaseLayout.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	location: PropTypes.object,
	pageContext: PropTypes.object,
	seo: PropTypes.object,
	altLangPages: PropTypes.shape({
		id: PropTypes.string,
		lang: PropTypes.string,
		path: PropTypes.string,
		edges: PropTypes.array,
	}).isRequired,
	footerData: PropTypes.object.isRequired,
	headerNavigation: PropTypes.object,
	homepagePaths: PropTypes.shape({
		edges: PropTypes.arrayOf(
			PropTypes.shape({
				node: PropTypes.shape({
					context: PropTypes.shape({
						locale: PropTypes.string,
					}),
					path: PropTypes.string,
				}),
			})
		),
	}).isRequired,
	alerts: PropTypes.number.isRequired,
	travelAlertsUrl: PropTypes.string.isRequired,
	pageType: PropTypes.oneOf([
		"LastMinute",
		"Packages",
		"Flights",
		"Hotels",
		"Cruises",
		"SmallGroups",
		"Other",
		"Other with widget",
	]),
	isAffiliatePage: PropTypes.bool.isRequired,
};
BaseLayout.defaultProps = {
	pageContext: undefined,
	location: undefined,
	seo: undefined,
	headerNavigation: undefined,
	pageType: "Packages",
};

export default BaseLayout;
export { BaseLayout };
